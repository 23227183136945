export default [
  "Aan de telefoon",
  "Aan het sporten",
  "Aan het strand",
  "Aan het werk",
  "Aan het winkelen",
  "Aan tafel",
  "Aan zee",
  "Achter de kassa",
  "Achter de schermen",
  "Bij de bakker",
  "Bij de buurvrouw",
  "Bij de dokter",
  "Bij de kapper",
  "Bij de kinderboerderij",
  "Bij de kinderopvang",
  "Bij de kleermaker",
  "Bij de pedicure",
  // "Bij de schoonheidsspecialiste",
  "Bij de tandarts",
  "Bij de wasserette",
  "Bij een club",
  "Bij een eetkraampje",
  // "Bij een toeristische attractie",
  "Bij schoonfamilie",
  "Bij vrienden",
  "In bed",
  "In de achtertuin",
  "In de bergen",
  "In de bibliotheek",
  "In de bioscoop",
  "In de dierentuin",
  "In de garage",
  "In de haven",
  "In de hemel",
  "In de kantine",
  "In de kelder",
  "In de kerk",
  "In de keuken",
  "In de lerarenkamer",
  "In de rechtbank",
  "In de ruimte",
  "In de speeltuin",
  "In de spotlight",
  "In de stad",
  "In de studio",
  "In de supermarkt",
  "In de trein",
  "In de wasstraat",
  "In de woestijn",
  "In de woonkamer",
  "In dromeland",
  "In een boomhut",
  "In een cafe",
  "In een discotheek",
  "In een fabriek",
  "In een kinderkamer",
  "In een museum",
  "In een oase",
  "In een pretpark",
  "In een pruikenwinkel",
  "In een restaurant",
  "In een ruimteschip",
  "In een tijdmachine",
  "In een voetbalstadion",
  "In een volkstuintje",
  "In het bos",
  "In het circus",
  "In het paradijs",
  "In het park",
  "In het regenwoud",
  "In het sprookjesbos",
  "In het theater",
  "In het ziekenhuis",
  "In luilekkerland",
  "Onder een waterval",
  "Onder water",
  "Onderweg",
  "Op 'n feestje",
  "Op de bank",
  "Op de boerderij",
  "Op de tribune",
  "Op een boot",
  "Op een concert",
  "Op een cursus",
  "Op een dagje uit",
  "Op een festival",
  "Op een spelletjesavond",
  "Op een verjaardag",
  "Op het balkon",
  "Op het kerkhof",
  "Op het podium",
  "Op het terras",
  "Op kantoor",
  "Op mars",
  "Op reis",
  "Op school",
  "Op straat",
  "Op TV",
  "Op vakantie",
  "Op visite",
  "Thuis",
];
