export default [
  "Achterdochtig",
  "Balend",
  "Bang",
  "Bedroefd",
  "Bedrogen",
  "Beledigd",
  "Beschaamd",
  "Beschermend",
  "Bewonderend",
  "Bezorgd",
  "Blij",
  "Boos",
  "Dankbaar",
  "Dolblij",
  "Eenzaam",
  "Ellendig",
  "Enthousiast",
  "Euforisch",
  "Extatisch",
  "Fobisch",
  "Furieus",
  "Gechoqueerd",
  "Geërgerd",
  "Geïrriteerd",
  "Gelukkig",
  "Geschrokken",
  "Gespannend",
  "Giechelig",
  "Hysterisch",
  "Ijverig",
  "Jaloers",
  "Jolig",
  "Kalm",
  "Machteloos",
  "Melancholisch",
  "Melig",
  "Moe",
  "Nerveus",
  "Nieuwsgierig",
  "Ongeduldig",
  "Ongelukkig",
  "Ongemakkelijk",
  "Ongerust",
  "Onstuimig",
  "Ontspannen",
  "Opgelucht",
  "Overrompeld",
  "Overweldigd",
  "Paniekerig",
  "Rusteloos",
  "Schuldig",
  "Somber",
  "Stralend",
  "Teder",
  "Tevreden",
  "Trots",
  "Verbijsterd",
  "Verbitterd",
  "Verbolgen",
  "Verdrietig",
  "Verlegen",
  "Verliefd",
  "Vernederd",
  "Verontwaardigd",
  "Verrukt",
  "Vervoerd",
  "Verward",
  "Verwonderd",
  "Vijandig",
  "Vredig",
  "Vrolijk",
  "Wanhopig",
  "Wantrouwend",
  "Weemoedig",
  "Woedend",
  "Zelfingenomen",
  "Zelfmedelijdend",
  "Zelfverzekerd",
  "Zenuwachtig",
  "Zwaarmoedig",
];
