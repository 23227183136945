<template>
  <Intro />

  <div class="machines">
    <div class="slot-machine" id="machine1">
      <div v-for="item in animals" :key="item">{{ item }}</div>
    </div>
    <div class="slot-machine" id="machine2">
      <div v-for="item in emotions" :key="item">{{ item }}</div>
    </div>
    <div class="slot-machine" id="machine3">
      <div v-for="item in locations" :key="item">{{ item }}</div>
    </div>
  </div>

  <p class="action"><a @click="start" class="button">Nog een keer!</a></p>

  <p class="action">
    <a
      href="https://www.goudenlijntjes.nl/diertjes-met-karakter/"
      class="button red"
      >Diertjes leren tekenen? Volg de cursus!</a
    >
  </p>

  <div class="video">
    <iframe
      src="https://player.vimeo.com/video/579942749?h=ea04669ea6&amp;color=FEC20E&amp;title=0&amp;byline=0&amp;portrait=0"
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="container">
    <h4>Nooit meer zonder inspiratie zitten?</h4>

    <p>
      Sluit je aan
      <a href="https://www.goudenlijntjes.nl/community/"
        >bij de Gouden Lijntjes community</a
      >! Maandelijkse live tekensessies, kersverse teken-ideeën en directe
      toegang tot een bomvol archief vol exclusieve video's, handige templates
      en stap-voor-stap tekentips.
    </p>

    <p>
      <a href="https://www.goudenlijntjes.nl/community/"
        >Gouden Lijntjes community</a
      >
    </p>
  </div>
</template>

<script>
import Intro from "./components/Intro.vue";
import SlotMachine from "jquery-slotmachine/lib/slot-machine.js";
import animals from "./lists/animals";
import emotions from "./lists/emotions";
import locations from "./lists/locations";

export default {
  name: "App",
  components: {
    Intro,
  },
  data() {
    return {
      animals: this.shuffle(animals),
      emotions: this.shuffle(emotions),
      locations: this.shuffle(locations),
      slot1: null,
      slot2: null,
      slot3: null,
    };
  },
  computed: {},
  methods: {
    start() {
      // Trigger to let the machine start
      this.slot1.shuffle();
      this.slot2.shuffle();
      this.slot3.shuffle();
    },
    onComplete(data) {
      // Run complete callback
      console.log(data);
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
  },
  mounted() {
    const options = { active: 0, delay: 500, spins: 50 };
    this.slot1 = new SlotMachine(document.querySelector("#machine1"), options);
    this.slot2 = new SlotMachine(document.querySelector("#machine2"), options);
    this.slot3 = new SlotMachine(document.querySelector("#machine3"), options);
    this.start();
  },
};
</script>

<style>
@font-face {
  font-family: "myFirstFont";
  src: url("./assets/BalooBhaina2-Regular.ttf");
}

#app {
  font-family: "myFirstFont", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  margin: 20px;
  max-width: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

html {
  background-image: url("./assets/background.png");
  background-size: cover;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 550px;
  margin: 0 auto;
}

.machines {
  margin-top: 2rem;
}
.slot-machine {
  height: 85px;
  overflow: hidden;
  font-size: 70px;
  font-weight: 300;
  line-height: 85px;
  margin: 1rem 0;
}
.slot-machine div {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (max-width: 440px) {
  .slot-machine {
    height: 50px;
    line-height: 60px;
  }
  .slot-machine div {
    font-size: 30px;
  }
}

h4 {
  margin: 4rem 0 0 0;
  font-size: 18px;
}

div.video {
  margin-top: 4rem;
  padding: 56.25% 0 0 0;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 4px 8px 18px -7px rgba(135, 135, 135, 0.6);
  max-width: 700px;
}
p.action,
p.cta {
  margin-top: 4rem;
  font-size: 20px;
}
p.cta {
  width: 90%;
  max-width: 700px;
  margin: 4rem auto 2rem;
}

@media screen and (max-width: 440px) {
  p.cta {
    font-size: 15px;
  }
}

.button {
  background-color: #ffc302;
  color: black;
  padding: 0.7rem 2rem 0.5rem;
  border-radius: 5px;
  font-size: 30px;
  line-height: 30px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 1000px;
}
.button.red {
  background-color: #ff4904;
  color: white;
  padding: 0.7rem 2rem 0.5rem;
  font-size: 20px;
  line-height: 20px;
}

@media screen and (max-width: 440px) {
  .button {
    font-size: 25px;
  }
  .button.red {
    font-size: 15px;
  }
}

a {
  color: #ff4904;
}
</style>
