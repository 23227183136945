export default [
  "Adelaar",
  "Alpaca",
  "Antilope",
  "Arend",
  "Beer",
  "Bever",
  "Bij",
  "Bizon",
  "Bonobo",
  "Brilslang",
  "Buffel",
  "Buizerd",
  "Capibara",
  "Cavia",
  "Chinchilla",
  "Damhert",
  "Das",
  "Dodo",
  "Dolfijn",
  "Dromedaris",
  "Duif",
  "Eekhoorn",
  "Eend",
  "Egel",
  "Ekster",
  "Eland",
  "Everzwijn",
  "Ezel",
  "Fazant",
  "Fret",
  "Fuut",
  "Gans",
  "Giraffe",
  "Gorilla",
  "Haan",
  "Haas",
  "Hamster",
  "Havik",
  "Hert",
  "Hommel",
  "Hond",
  "Hyena",
  "IJsbeer",
  "IJsvogel",
  "Impala",
  "Jaguar",
  "Kakkerlak",
  "Kalkoen",
  "Kanarie",
  "Kangoeroe",
  "Kat",
  "Kever",
  "Kikker",
  "Kip",
  "Koe",
  "Kolibri",
  "Konijn",
  "Kraai",
  "Kreeft",
  "Krokodil",
  "Kwal",
  "Lama",
  "Leeuw",
  "Leguaan",
  "Lemur",
  "Libelle",
  "Lieveheersbeestje",
  "Luiaard",
  "Luipaard",
  "Makreel",
  "Meeuw ",
  "Mier",
  "Mol",
  "Mot",
  "Muis",
  "Neushoorn",
  "Nijlpaard",
  "Octopus",
  "Olifant",
  "Ooievaar",
  "Orang Oetan",
  "Otter",
  "Paard",
  "Pad",
  "Panda",
  "Panter",
  "Pauw",
  "Pinguin",
  "Ram",
  "Rat",
  "Rendier",
  "Ringstaartmaki",
  "Rups",
  "Salamander",
  "Schildpad",
  "Schorpioen",
  "Slang",
  "Spin",
  "Steenbok",
  "Stekelvarken",
  "Stier",
  "Tapir",
  "Tekkel",
  "Tijger",
  "Uil",
  "Varken",
  "Vis",
  "Vleermuis",
  "Vlinder",
  "Vos",
  "Walrus",
  "Walvis",
  "Wasbeer",
  "Wolf",
  "Wombat",
  "Zebra",
  "Zeester",
  "Zwaan",
  "Zwaluw",
];
