<template>
  <div>
    <a href="https://www.goudenlijntjes.nl/">
      <img src="../assets/logo.png" alt="Gouden Lijntjes"
    /></a>
    <h1>Inspiratie-generator</h1>
  </div>
</template>

<script>
export default {
  name: "Intro",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 50px;
  margin: 1rem 0;
  line-height: 1;
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 30px;
  }
}
img {
  width: 90%;
  max-width: 500px;
}
</style>
